<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0"
    >
      <div
        v-if="!specificClient"
        class="d-flex align-center justify-end"
      >
        <span class="input-label">
          Nowy klient
        </span>

        <v-switch
          :value="showNewClientForm"
          @change="changeNewClientFormStatus(!showNewClientForm)"
          :disabled="specificClient || showEditClientForm"
          hide-details
          class="ml-2 my-0 pa-0"
        />
      </div>
    </v-col>
    <v-col class="pb-0 pt-4">
      <v-row>
        <v-col
          cols="12"
          v-show="showNewClientForm"
          class="pt-0"
        >
          <ClientForm
            ref="newClientPartial"
            :new-client-middleman="newClientMiddleman"
            :is-adding-client-to-middleman-mode="isAddingClientToMiddlemanMode"
            actions
            @submitClientForm="createClient"
            @close="changeNewClientFormStatus(false)"
          />
        </v-col>

        <v-col
          cols="12"
          v-if="showEditClientForm"
          class="pt-0"
        >
          <ClientForm
            ref="editClientPartial"
            :editing-client="chosenClient"
            actions
            @submitClientForm="updateClient"
            @close="changeEditClientFormStatus(false)"
          />
        </v-col>

        <template v-if="!showEditClientForm && !showNewClientForm">
          <v-col
            cols="12"
            class="pt-0"
            :class="{'pb-0' : showAddressForm || chosenClient}"
          >
            <v-menu
              v-model="showClientsList"
              v-bind="attributes.menu"
              min-width="468"
              max-width="auto"
              v-if="!specificClient"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  ref="searchClientField"
                  v-model="query"
                  label="Szukaj klienta"
                  :disabled="showAddressForm"
                  :placeholder="getSearchShortkeysPlaceholder('klienta')"
                  outlined
                  clearable
                  autocomplete="off"
                  @input="searchClients"
                  @click:clear="clearClientsList"
                  v-bind="attrs"
                  v-on="on"
                  autofocus
                  v-shortkey="searchShortkeys"
                  @shortkey.native="focusSearchClientField"
                >
                  <template #prepend-inner>
                    <Icon
                      name="search"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>

              <v-data-table
                :value="selectedClient"
                :headers="clientsTableHeaders"
                :items="parsedClientsList"
                :loading="searchProcessing"
                class="results-table"
                :items-per-page="-1"
                hide-default-footer
                fixed-header
                single-select
                @click:row="selectClient"
              />
            </v-menu>
          </v-col>

          <template v-if="!showAddressForm">
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-row
                class="pl-1 mb-3"
                :class="[specificClient ? 'mt-3' : 'mt-n1']"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <div class="input-label d-flex align-center text-14">
                    <v-input
                      :value="orderData.clientId"
                      :rules="[rules.required]"
                      class="validated-label"
                      hide-details
                    >
                      Informacje o kliencie:
                    </v-input>

                    <span
                      v-if="chosenClient && chosenClient.blocked"
                      class="red--text d-flex align-center ml-1"
                    >
                      <img
                        src="@/assets/icons/block-mark.svg"
                        class="mr-1"
                        alt="blocked"
                      > Klient zablokowany
                    </span>

                    <v-btn
                      color="primary"
                      type="submit"
                      text
                      small
                      @click="changeEditClientFormStatus(true)"
                      :disabled="!orderData.clientId"
                    >
                      Edytuj klienta
                    </v-btn>
                  </div>
                  <div class="chosen-client-details">
                    {{ chosenClient ? chosenClient.name : '-' }}
                  </div>
                  <div class="chosen-client-details mt-1">
                    {{ chosenClient ? chosenClient.invoiceAddress : '-' }}
                  </div>
                </v-col>
                <v-col
                  v-for="param in clientBioParams"
                  :key="param.text"
                  :cols="param.cols"
                  class="pt-1 pb-0 pr-0 text-14"
                >
                  <span class="input-label mr-1">{{ param.text }}: </span>
                  <span class="chosen-client-details">{{ param.value || '-' }}</span>
                </v-col>
              </v-row>
            </v-col>

            <v-row
              v-if="chosenClient && chosenClient.isMiddleman"
              class="ml-1"
            >
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span class="input-label mr-2">
                  Zamów na innego klienta
                </span>
                <v-switch
                  v-model="orderAsMiddleman"
                  hide-details
                  class="ml-2 my-0 pa-0"
                  @change="setMiddleman"
                />
              </v-col>

              <v-col
                cols="6"
                class="d-flex align-center justify-end"
              >
                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="!orderAsMiddleman"
                  @click="showNewMiddlemanClientForm"
                >
                  Dodaj klienta
                </v-btn>
              </v-col>
            </v-row>

            <template v-if="chosenClient && chosenClient.isMiddleman && orderAsMiddleman">
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-autocomplete
                  :value="orderData.clientId"
                  outlined
                  :items="middlemanClients"
                  :rules="[rules.required]"
                  label="Klient końcowy"
                  item-text="name"
                  item-value="id"
                  append-icon="mdi-chevron-down"
                  placeholder="Wybierz klienta końcowego"
                  dense
                  @input="emitUpdate('order.clientId', $event)"
                />
              </v-col>

              <v-col
                cols="12"
                class="d-flex align-center pt-0 mt-n2 mb-2"
              >
                <span class="input-label mr-2">
                  Faktura na pośrednika
                </span>
                <v-switch
                  v-model="invoiceForMiddleman"
                  :disabled="!orderData.clientId"
                  hide-details
                  class="ml-2 my-0 pa-0"
                  @change="setPayer"
                />
              </v-col>
            </template>

            <v-col
              v-if="showUnmatchedAddressWarning"
              class="mb-4 mt-n4"
            >
              <Warning
                message="Sprawdź zgodność lokalizacji z adresem zamówienia!"
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                :value="orderData.addressId"
                outlined
                :items="addresses"
                :disabled="!orderData.clientId && !orderData.middlemanId"
                :rules="orderData.clientId ? [rules.required] : []"
                label="Lokalizacja"
                hide-details
                :item-text="(item) => item.name || item.formattedAddress"
                item-value="id"
                append-icon="mdi-chevron-down"
                placeholder="Wybierz lokalizację zlecenia"
                :no-data-text="noAddressItemsText"
                dense
                @input="emitUpdate('order.addressId', $event)"
              >
                <template #item="{item}">
                  <div class="d-flex justify-space-between w-100">
                    <span>{{ item.name || item.formattedAddress }}</span>
                    <v-icon
                      v-if="item.isNotOrderTemplateMatchingAddress"
                      size="16"
                      color="red"
                    >
                      mdi-alert
                    </v-icon>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col class="pt-0 d-flex align-center justify-space-between">
              <div>
                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="!orderData.clientId"
                  @click="$emit('addAddress')"
                >
                  Dodaj lokalizacje
                </v-btn>

                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="!orderData.addressId"
                  @click="$emit('editAddress')"
                >
                  Edytuj lokalizacje
                </v-btn>
              </div>

              <div
                class="input-label"
                v-if="distance"
              >
                Odległość od bazy w linii prostej: {{ distance }} km
              </div>
            </v-col>

            <v-col
              cols="12"
              class="pt-1 pb-0 pr-0 text-14"
            >
              <span class="input-label mr-1">Numer telefonu lokalizacji: </span>
              <span class="chosen-client-details">{{ selectedAddress.phoneNumber || '-' }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pt-1 pb-0 pr-0 text-14"
            >
              <span class="input-label mr-1">Notatka do lokalizacji: </span>
              <span class="chosen-client-details">{{ selectedAddress.notes || '-' }}</span>
            </v-col>

            <v-col cols="12">
              <SuggestedOrderPrice
                :all-disabled="disableSuggestedOrderPrice"
                :orders-disabled="isOrderFromTemplate"
                :key="chosenClient && chosenClient.id"
                :order-data="orderData"
                :course-type="courseType"
                :client="chosenClient && chosenClient.id ? chosenClient : {}"
                :selected-address="selectedAddress"
                :search-processing="searchProcessing"
                @populateOrder="populateOrder"
                @populateProduct="populateProduct"
              />
            </v-col>
          </template>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ClientForm from './ClientForm'
import SuggestedOrderPrice from './SuggestOrderPrice.vue'
import api from '../../../api/v1'
import rules from '../../../utils/validators'
import { updateOrderFields, updateProductFields, updatePaymentFields } from '../../../utils/fieldsUpdate'
import { searchShortkeys, getSearchShortkeysPlaceholder } from '../../../const/shortKeys'
import attributes from '../../../const/datePickerAttrributes'
import debounce from 'lodash/debounce'
import { mapState, mapActions } from 'vuex'

const clientsTableHeaders = [
  { text: 'Nazwa', value: 'name' },
  { text: 'NIP', value: 'invoiceNip' },
  { text: 'Nr BDO', value: 'bdoNumber' },
  { text: 'Adres', value: 'address' },
  { text: 'Numer Telefonu', value: 'phoneNumber' }
]

export default {
  components: {
    SuggestedOrderPrice,
    ClientForm
  },
  props: {
    showAddressForm: {
      type: Boolean,
      default: false
    },
    specificClient: {
      type: Boolean,
      default: false
    },
    addresses: {
      type: Array,
      default: () => []
    },
    orderData: {
      type: Object,
      required: true
    },
    courseType: {
      type: String,
      default: 'Podstawienie'
    },
    distance: {
      type: Number,
      default: 0
    },
    noAddressItemsText: {
      type: String,
      default: 'Brak elementów do wyświetlenia'
    },
    isAddingClientToMiddlemanMode: {
      type: Boolean,
      default: false
    },
    isEditingOrder: {
      type: Boolean,
      default: false
    },
    disableSuggestedOrderPrice: {
      type: Boolean,
      default: false
    },
    orderFromTemplateData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      searchShortkeys,
      attributes,
      rules,
      clientsTableHeaders,
      selectedClient: [],
      clientsList: [],
      query: '',
      chosenClient: null,
      showNewClientForm: false,
      showEditClientForm: false,
      searchProcessing: false, // todo check if necessary
      showClientsList: false,
      orderAsMiddleman: false,
      middlemanClients: [],
      invoiceForMiddleman: true,
      newClientMiddleman: null
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      singleClient: state => state.client.entity,
      isLoadingAddresses: state => state.addresses.isProcessing,
    }),
    isOrderFromTemplate() {
      return !!this.orderFromTemplateData
    },
    selectedAddress () {
      return this.addresses?.find(address => address.id === this.orderData.addressId) || {}
    },
    parsedClientsList () {
      return this.clientsList.map((client) => ({
        ...client,
        address: `${client.invoiceAddress}, ${client.invoiceCity}`
      }))
    },
    clientBioParams () {
      const { invoiceNip, phoneNumber, bdoNumber, bdoType, settlementType, notes } = this.chosenClient || {}

      const params = [
        { text: 'Telefon', value: phoneNumber, cols: 12 },
        { text: 'Typ dokumentu', value: settlementType, cols: 7 },
        { text: 'Numer NIP', value: invoiceNip, cols: 5 },
        { text: 'Logika BDO', value: bdoType, cols: 7 },
        { text: 'Numer BDO', value: bdoNumber, cols: 5 },
        { text: 'Notatka do klienta', value: notes, cols: 12 },
      ]
      if (this.isOrderFromTemplate) params.push({ text: 'Adres zamówienia', value: this.orderFromTemplateData.recipientStreet, cols: 12 })
      return params
    },
    showUnmatchedAddressWarning () {
      return this.addresses.some(address => address.isNotOrderTemplateMatchingAddress) && !this.isLoadingAddresses
    }
  },
  watch: {
    showAddressForm (show) {
      if (show) this.query = this.chosenClient.name
    },
    'orderData.addressId' (id) {
      if (id) this.selectAddress(id)
    },
    'chosenClient.id' () {
      if (this.orderAsMiddleman) this.getMiddlemanClients()
    }
  },
  mounted () {
    if (this.specificClient) {
      this.selectClient(this.singleClient)
      if (this.orderData.addressId) this.selectAddress(this.orderData.addressId)
    }
  },
  methods: {
    ...mapActions({
      addNewClient: 'clients/addNewItem',
      editClient: 'clients/editItem',
      getMiddlemen: 'middlemen/getItems',
      showSnackbar: 'snackbar/showSnackbar',
      setSingleClient: 'client/setSingleClient'
    }),
    getSearchShortkeysPlaceholder,
    changeNewClientFormStatus (status) {
      this.showNewClientForm = status
      this.showClientsList = false
      this.emitUpdate('isAddingClientToMiddlemanMode', false)
      this.emitUpdate('isClientFormOpen', status)
      this.emitUpdate('showAddressForm', false)
    },
    changeEditClientFormStatus (status) {
      this.showEditClientForm = status
      this.showClientsList = false
    },
    setMiddleman(setAsMiddleman) {
      if (setAsMiddleman) {
        this.emitUpdate('order.clientId', null)
        this.emitUpdate('order.payerId', this.chosenClient.id)
        this.emitUpdate('order.middlemanId', this.chosenClient.id)
        this.getMiddlemanClients()
      } else {
        this.emitUpdate('order.middlemanId', null)
        this.emitUpdate('order.clientId', this.chosenClient.id)
      }
    },
    getMiddlemanClients() {
      return api.getItems({ tableName: 'clients', params: { filters: { middlemanIds: [this.chosenClient.id], withoutPatron: true } } })
        .then(res => {
          this.middlemanClients = res.data.collection
        })
    },
    setPayer(invoiceForMiddleman) {
      if (invoiceForMiddleman) {
        this.emitUpdate('order.payerId', this.chosenClient.id)
      } else {
        this.emitUpdate('order.payerId', this.orderData.clientId)
      }
    },
    selectClient (client, row) {
      if (row) row.select(true)
      if (client.declarationNotPresent) this.showSnackbar({ type: 'error', message: ['Ten klient nie posiada oświadczenia BDO'] })
      this.orderAsMiddleman = !!this.orderData.middlemanId
      this.selectedClient = [client]
      this.chosenClient = client
      this.showClientsList = false
      this.emitUpdate('clientType', client.clientType)
      if (!this.specificClient || !this.orderData?.middlemanId) this.emitUpdate('order.clientId', client.id)
      if (!this.isEditingOrder) {
        this.emitUpdate('order.middlemanId', null)
        this.setSingleClient(client) // we need to have client data in store for AddressForm component (clientType value)
        this.emitUpdate('order.payerId', client.id)
        this.emitUpdate('payment.settlementType', client.settlementType)
        if (!this.isOrderFromTemplate) this.emitUpdate('payment.paymentType', client.paymentType) // is order from template - payment type should not be changed after creating and selecting client
        this.emitUpdate('payment.paymentDueDays', client.paymentDueDays)
      }
    },
    selectAddress (id) {
      if (!this.isEditingOrder) this.emitUpdate('order.addressId', id)
      const { paymentType, paymentDueDays } = this.selectedAddress
      if (paymentType) this.emitUpdate('payment.paymentType', paymentType)
      if (paymentDueDays) this.emitUpdate('payment.paymentDueDays', paymentDueDays)
    },
    clearClientsList () {
      this.clientsList = []
    },
    clearClientData () {
      this.emitUpdate('order.clientId', null)
      this.emitUpdate('order.addressId', null)
    },
    searchClients: debounce(function () {
      this.searchProcessing = true
      this.showClientsList = true
      api.searchClients({
        departmentId: this.department.id,
        filters: {
          query: this.query
        }
      }).then((resp) => {
        this.clientsList = resp.data
        const isClientIncludedInClientsList = resp.data.map(client => client.id).includes(this.orderData.clientId)
        if (!isClientIncludedInClientsList) {
          this.clearClientData()
        }
      })
        .finally(() => {
          this.searchProcessing = false
        })
    }, 700),
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    createClient (params) {
      this.addNewClient({ params })
        .then((resp) => {
          if (resp.isMiddleman) this.getMiddlemen()
          this.changeNewClientFormStatus(false)
          if (this.isAddingClientToMiddlemanMode) {
            this.emitUpdate('order.clientId', resp.id)
            this.middlemanClients.push(resp)
          } else {
            this.clientsList.push(resp)
            this.selectClient(resp)
          }
          this.$nextTick(() => {
            if (!this.orderData.middlemanId) this.$emit('addAddress') // middleman is chosen - we omit step with creating address to new client
          })
          const { email, phoneNumber, invoiceAddress, invoiceCity } = params
          this.emitUpdate('savedClientData', {
            email,
            phoneNumber,
            address: { formattedAddress: `${invoiceAddress || ''} ${invoiceAddress && invoiceCity ? ', ' : ''} ${invoiceCity || ''}` },
          })
        })
    },
    updateClient (updatedData) {
      const params = {
        tableName: 'clients',
        params: updatedData,
        id: this.chosenClient.id
      }
      this.editClient(params)
        .then((resp) => {
          if (params.params.isMiddleman) this.getMiddlemen()
          this.changeEditClientFormStatus(false)
          const index = this.clientsList.findIndex(client => client.id === this.chosenClient.id)
          this.clientsList[index] = resp
          this.$nextTick(this.selectClient(resp))
        })
    },
    async populateOrder (order) {
      const data = {
        ...order,
        vatPercentage: this.courseType === 'Podstawienie' ? 8 : 23
      }
      this.emitUpdate('isPriceCalculationAllowed', false)
      await updateOrderFields.call(this, data)
      updatePaymentFields.call(this, data)
      this.emitUpdate('isPriceCalculationAllowed', true)
    },
    async populateProduct (product) {
      const data = {
        ...product,
        vatPercentage: this.courseType === 'Podstawienie' ? 8 : 23,
        origin: 'P' // price origin is from product
      }
      this.emitUpdate('isPriceCalculationAllowed', false)
      await updateProductFields.call(this, data)
      this.emitUpdate('isPriceCalculationAllowed', true)
    },
    focusSearchClientField () {
      this.$refs.searchClientField.focus()
    },
    showNewMiddlemanClientForm () {
      this.newClientMiddleman = { ...this.chosenClient }
      this.showNewClientForm = true
      this.$emit('showNewMiddlemanClientForm')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(3){
white-space: nowrap !important;
}
</style>
